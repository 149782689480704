/* eslint-disable react/no-danger */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Grid = styled.div`
  display: grid;
  --column-gap: 7rem;
  --row-gap: 8vh;
  --mwidth: 400px;
  --columns: 1;
  width: 100%;
  margin: 0 auto 8rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: var(--column-gap);
  grid-row-gap: var(--row-gap);
  justify-content: center;
  padding: 0 0 6rem;
  margin-top: 0;

  .grid__item {
    margin: 0;
    padding: 0;
    align-self: start;
    display: grid;
    grid-template-areas:
      "pretitle"
      "title"
      "..."
      "counter"
      "description";
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    h3 {
      text-align: left;
    }
  }

  .grid__fig {
    // grid-area: 1 / 2 / 3 / 4;
    margin: 0;
    text-align: center;
  }

  .grid__img {
    max-width: 100%;
  }

  .grid__pretitle {
    font-weight: 900;
    color: #c75784;
    font-size: 2rem;
    margin: 0 0 .6rem;
    grid-area: pretitle;
    display: flex;
  }

  .grid__title {
    font-weight: 900;
    grid-area: title;
    margin: 0;
    /* font-size: 2.6rem; */
    padding: 0 0 10px;
  }

  .grid__counter {
    font-weight: 900;
    color: #c8608b;
    font-size: 1.6rem;
    grid-area: counter;
    align-self: end;
    justify-self: end;
    line-height: 1;
    padding: 0 0 20px;
  }

  .grid__description {
    width: 100%;
    grid-area: description;
    color: #fff;
    color: #fff;
    text-align: left;
    margin: 0;

    sup {
      color: #5dd882;
      cursor: help;
      border-bottom: 1px dotted #5dd882;
    }
  }

  @media only screen and (min-width: 610px) {
    .grid__description {
      text-align: justify;
      hyphens: auto;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
    }
  }

    // 3d effect
  .flip-card {
    background-color: transparent;
    max-width: 276px;
    height: 290px;
    margin: 0 auto;
    padding: 5px 0;
    width: 100%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .flip-card-front {

  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
`;

const Products = () => {
  // eslint-disable-next-line no-unused-vars
  const data = useStaticQuery(graphql`{
    zniwiarka_1_rewers: file(relativePath: {regex: "/zniwiarka-1-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    zniwiarka_1_awers: file(relativePath: {regex: "/zniwiarka-1-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    zniwiarka_2_rewers: file(relativePath: {regex: "/zniwiarka-2-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    zniwiarka_2_awers: file(relativePath: {regex: "/zniwiarka-2-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    zaglowiec_2_rewers: file(relativePath: {regex: "/zaglowiec-2-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    zaglowiec_2_awers: file(relativePath: {regex: "/zaglowiec-2-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    polonia_5_awers: file(relativePath: {regex: "/polonia-5-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    polonia_5_rewers: file(relativePath: {regex: "/polonia-5-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    pilsudski_5_awers: file(relativePath: {regex: "/pilsudski-5-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    pilsudski_5_rewers: file(relativePath: {regex: "/pilsudski-5-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    nike_5_awers: file(relativePath: {regex: "/nike-5-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    nike_5_rewers: file(relativePath: {regex: "/nike-5-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    sztandar_5_awers: file(relativePath: {regex: "/sztandar-5-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    sztandar_5_rewers: file(relativePath: {regex: "/sztandar-5-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    traugutt_10_awers: file(relativePath: {regex: "/traugutt-10-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    traugutt_10_rewers: file(relativePath: {regex: "/traugutt-10-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    sobieski_10_awers: file(relativePath: {regex: "/sobieski-10-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    sobieski_10_rewers: file(relativePath: {regex: "/sobieski-10-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    konstytucja_5_awers: file(relativePath: {regex: "/konstytucja-5-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    konstytucja_5_rewers: file(relativePath: {regex: "/konstytucja-5-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    chrobry_10_awers: file(relativePath: {regex: "/chrobry-10-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    chrobry_10_rewers: file(relativePath: {regex: "/chrobry-10-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    chrobry_20_awers: file(relativePath: {regex: "/chrobry-20-awers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
    chrobry_20_rewers: file(relativePath: {regex: "/chrobry-20-rewers/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 276
        )
      }
    }
  }
  `);

  // eslint-disable-next-line react/no-unstable-nested-components
  function GridItem(props) {
    const {
      value, desc, title, years, srcRevers, srcObverse,
    } = props;
    return (
      <div className="grid__item">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="grid__fig flip-card-front">
              <GatsbyImage
                image={getImage(data[srcRevers])}
                alt={`${value} ${title} - rewers`}
              />
            </div>
            <div className="grid__fig flip-card-back">
              <GatsbyImage
                image={getImage(data[srcObverse])}
                alt={`${value} ${title} - awers`}
              />
            </div>
          </div>
        </div>
        <span className="grid__pretitle">{ value }</span>
        <h3 className="grid__title">{ title }</h3>
        <Tippy content="Wybita z datą"><span className="grid__counter ts">{ years }</span></Tippy>
        <p className="grid__description" dangerouslySetInnerHTML={{ __html: desc }}></p>
      </div>
    );
  }

  return (
    <section id="monety-ii-rzeczypospolitej">
      <h2
        className="text-center"
        style={{ marginBottom: '4rem' }}
      >
        <span>Polskie monety obiegowe II&nbsp;Rzeczypospolitej</span>
      </h2>
      <Grid>
        <GridItem
          srcObverse="zniwiarka_1_awers"
          srcRevers="zniwiarka_1_rewers"
          value="1 zł"
          title="Żniwiarka"
          years="1924, 1925"
          desc="Monety jednozłotowe były bite w&nbsp;dwóch mennicach, z&nbsp;datą 1924 w&nbsp;Paryżu - stemplem płytkim, z&nbsp;datą 1925 w&nbsp;Londynie - stemplem głębokim."
        />
        <GridItem
          srcObverse="zniwiarka_2_awers"
          srcRevers="zniwiarka_2_rewers"
          value="2 zł"
          title="Żniwiarka"
          years="1924, 1925"
          desc="Dwuzłotówka była bita w&nbsp;mennicach w&nbsp;Paryżu, Birmingham, Filadelfii i&nbsp;Londynie. Moneta 2 zł z&nbsp;Filadelfii, jest jedyną polską monetą wprowadzoną do obiegu, która została wybita stemplem odwróconym."
        />
        <GridItem
          srcObverse="zaglowiec_2_awers"
          srcRevers="zaglowiec_2_rewers"
          value="2 zł"
          title="Żaglowiec"
          years="1936"
          desc="Po odzyskaniu niepodległości, Polska zyskała odcinek wybrzeża, na którym nie było żadnego większego portu. w&nbsp;1921 roku zapadła decyzja o&nbsp;budowie niezależnego portu w&nbsp;Gdyni - wówczas niewielkiej wiosce rybackiej, zamieszkałej przez niewiele ponad tysiąc mieszkańców. W&nbsp;1934 r. port w&nbsp;Gdyni był już&nbsp;największym i&nbsp;najnowocześniejszym portem nad Bałtykiem. Dwuzłotówka z&nbsp;żaglowcem została wyemitowana w&nbsp;1936 r. z&nbsp;okazji 15. rocznicy budowy portu. Za projekt odpowiada Józef Aumiller (medalier, rzeźbiarz, projektant monet i&nbsp;znaczków pocztowych). Na awersie widnieje rok 1936, chociaż sama moneta była bita w&nbsp;latach 1937 i&nbsp;1938."
        />
        <GridItem
          srcObverse="polonia_5_awers"
          srcRevers="polonia_5_rewers"
          value="5 zł"
          title="Polonia"
          years="1932, 1933, 1934"
          desc='Jedna z&nbsp;najbardziej popularnych monet okresu międzywojennego. Autorem projektu był Antonii Madeyski. "Artysta projektując wyidealizowany wizerunek Polonii, inspirował się rysami twarzy Janiny Żółtowskiej, w&nbsp;których dopatrywał się idealnego piękna. Przez fakt, iż Janina Żółtowska była także modelem Madeyskiego podczas prac nad nagrobkiem Jadwigi na Wawelu, wiele osób wizerunek Polonii błędnie przypisuje Królowej. Warto podkreślić, że ta bezpodstawna interpretacja nie posiada głębszego uzasadnienia i&nbsp;pozbawiona jest faktów źródłowych i&nbsp;ikonograficznych" <sup title="M. Praski, <em>Polonia Madeyskiego</em>, „Spotkania z zabyt-<br>kami”, 7-8 2012, s. 48." class="annotation">[1]</sup>'
        />
        <GridItem
          srcObverse="pilsudski_5_awers"
          srcRevers="pilsudski_5_rewers"
          value="5 zł"
          title="Piłsudski (strzelecki)"
          years="1934"
          desc='Moneta okolicznościowa, upamiętniająca 20. rocznicę wymarszu Pierwszej Kompanii Kadrowej Legionów. Monetę zaprojektował Stanisław Kazimierz Ostrowski (polski rzeźbiarz współczesny, który jest również autorem Grobu Nieznanego Żołnierza, odsłoniętego w&nbsp;1925 roku, pod kolumnadą Pałacu Saskiego w&nbsp;Warszawie). Na rewersie znajduje się&nbsp;wizerunek marszałka Józefa Piłsudskiego, natomiast awers zawiera orła strzeleckiego autorstwa Czesława Jarnuszkiewicza (generał brygady Wojska Polskiego). Orzeł strzelecki, pozbawiony korony, stoi na peltarionie <sup title="Tarcza używana przez antyczną grecką lekką piechotę" class="annotation">[2]</sup>. Wizerunek marszałka Piłsudskiego, podobnie jak wizerunek "Polonii" znajduje się&nbsp;na monetach 2, 5 i&nbsp;10 zł.'
        />
        <GridItem
          srcObverse="nike_5_awers"
          srcRevers="nike_5_rewers"
          value="5 zł"
          title="Nike"
          years="1928, 1930, 1931, 1932"
          desc='Nike to pierwsza, wprowadzona do powszechnego obiegu moneta pięciozłotowa II RP, z&nbsp;napisem na rancie, który brzmiał: <em>"SALUS REIPUBLICAE SUPREMA LEX"</em> <sup  title="z łac. „dobro Rzeczypospolitej najwyższym prawem” -&nbsp;miał uzasadniać przewrót majowy (1926 r.)" class="annotation">[3]</sup>. "Autorem projektu był Edward Wittig. Postać kroczącej bogini zwycięstwa wziął z&nbsp;własnego projektu pomnika Wolności i&nbsp;Zwycięstwa, który miał stanąć w Gdyni. Zamówienie Ministerstwa Skarbu opiewało na 28 milionów sztuk pięciozłotówek, co postawiło mennicę przed niezwykle trudnym zadaniem. W&nbsp;początkowym okresie mennica nie była w&nbsp;stanie samodzielnie wykonywać odpowiedniej ilości krążków, a&nbsp;tym bardziej gotowych monet. Metodą prób i&nbsp;błędów ustalono, że najwłaściwsza kolejność etapów produkcji krążków jest taka: wycinanie krążków z&nbsp;blachy niebielonej, wyżarzanie, bielenie, otaczanie. Okazało się jednak, że tak przygotowane krążki są matowe i&nbsp;srebro z&nbsp;ich powierzchni przywiera podczas bicia do stempli. Problem rozwiązano wykonując automaty do polerowania krążków." <sup title="J. Chałupski, Specjalizowany katalog monet polskich XX i XXI w. część druga, II Rzeczpospolita Generalne Gubernatorstwo 1918-1945, wyd. drugie, Sosnowiec 2013, s. 198-199" class="annotation">[4]</sup>'
        />
        <GridItem
          srcObverse="sztandar_5_awers"
          srcRevers="sztandar_5_rewers"
          value="5 zł"
          title="Sztandar"
          years="1930"
          desc='Moneta okolicznościowa, upamiętniająca 100-lecie Powstania Listopadowego. Na rancie widnieje wklęsły napis <em>"SALUS REIPUBLICAE SUPREMA LEX"</em>. Co ciekawe moneta nie posiada nazwy emitenta.'
        />
        <GridItem
          srcObverse="traugutt_10_awers"
          srcRevers="traugutt_10_rewers"
          value="10 zł"
          title="Romuald Traugutt"
          years="1933"
          desc="Moneta okolicznościowa upamiętniająca 70. rocznicę Powstania Styczniowego."
        />
        <GridItem
          srcObverse="sobieski_10_awers"
          srcRevers="sobieski_10_rewers"
          value="10 zł"
          title="Jan III Sobieski"
          years="1933"
          desc="Moneta okolicznościowa upamiętniająca 250. rocznicę Odsieczy Wiedeńskiej."
        />
        <GridItem
          srcObverse="konstytucja_5_awers"
          srcRevers="konstytucja_5_rewers"
          value="5 zł"
          title="Konstytucja"
          years="1925"
          desc='Moneta uznawana za jedną z&nbsp;najpiękniejszych monet okresu międzywojennego. Pięciozłotówka ze stopu srebra o&nbsp;próbie 900, była bita w&nbsp;kilku wersjach, dwie główne to: stempel płytki (81 perełek) i&nbsp;stempel głęboki (100 perełek). Na rancie widniał napis: <em>"SALUS REIPUBLICAE SUPREMA LEX"</em>. Moneta nigdy nie trafiła do obiegu, ponieważ wartość srebra w&nbsp;niej zawartego przewyższała wartość nominalną.'
        />
        <GridItem
          srcObverse="chrobry_10_awers"
          srcRevers="chrobry_10_rewers"
          value="10 zł"
          title="Bolesław Chrobry"
          years="1925"
          desc="Moneta bulionowa, o&nbsp;próbie 900, podobnie jak jej odpowiednik dwudziestozłotowy, upamiętniała 900. rocznicę koronacji Bolesława Chrobrego."
        />
        <GridItem
          srcObverse="chrobry_20_awers"
          srcRevers="chrobry_20_rewers"
          value="20 zł"
          title="Bolesław Chrobry"
          years="1925"
          desc=""
        />
      </Grid>
    </section>
  );
};

export default Products;
